import { FC, useContext } from "react"
import { Controller } from "react-hook-form"
import Select from "react-select"
import { Col, Input, Label, Row } from "reactstrap"
import { AppContext } from "../../../../../context"
import { CommissionOverwriteFormProps } from "../../../../../interfaces"
import { customSelectStyleNew } from "../../../../styled/customSelect"

const CommissionOverwriteForm: FC<CommissionOverwriteFormProps> = ({ getValues, register, setValue, control, errors, commissionOverwrite }) => {
  const { theme } = useContext(AppContext)

  return <Row className="my-4 fs-14" >
    <Col sm='8' className="mb-3">
      <Label className="text-14 text-blackele pb-2">Overwrite Type <span className="text-danger">*</span></Label>
      <Controller
        name="type"
        defaultValue={commissionOverwrite?.type && { value: commissionOverwrite?.type.toUpperCase(), label: commissionOverwrite?.type }}
        rules={{ required: { value: true, message: "Overwrite Type is a required field" } }}
        control={control}
        as={Select}
        options={[{ value: "REFERRAL", label: "Referral" }, { value: "TEAM", label: "Team" },]}
        onChange={(value) => setValue('assignedManagerId', value)}
        innerRef={register}
        styles={{ ...customSelectStyleNew(theme) }}
        placeholder="Select Type"
      />
      <small className="text-danger pt-2">
        {errors?.type?.message}
      </small>
    </Col>

    <Col sm='6' className="mb-3 custom-form-input form-input">
      <Label className="text-14 text-blackele pb-2">Valid Days {
        getValues().type?.value === 'REFERRAL' && <span className="text-danger">*</span>} </Label>
      <Controller
        name="validDays"
        defaultValue={commissionOverwrite?.validDays}
        rules={{ required: { value: getValues().type?.value === 'REFERRAL', message: "Valid Days is a required field" } }}
        control={control}
        render={(field) => (
          <Input
            {...field}
            placeholder="Valid Days"
            type="text"
            onChange={({ target: { value } }) => {
              const filteredValue = value.replace(/,/g, '').replace(/\D/g, "")
              setValue("validDays", filteredValue.slice(0, 3))
            }}
            onFocus={(value) => value.target.value && setValue("validDays", value.target.value.replaceAll(" days", ""))}
            onBlur={(value) => value.target.value && setValue("validDays", value.target.value + " days")}
          />
        )}
      />
      <small className="text-danger pt-2">
        {errors?.validDays?.message}
      </small>
    </Col>

    <Col sm='6' className="mb-3 custom-form-input form-input">
      <Label className="text-14 text-blackele pb-2">Overwrite Percentage <span className="text-danger">*</span></Label>
      <Controller
        name="percentage"
        defaultValue={commissionOverwrite?.percentage}
        rules={{ required: { value: true, message: "Overwrite Percentage is a required field" } }}
        control={control}
        render={(field) => (
          <Input
            {...field}
            placeholder="Overwrite Percentage"
            type="text"
            onChange={({ target: { value } }) => {
              const filteredValue = value.replace(/,/g, '').replace(/\D/g, "")
              setValue("percentage", filteredValue.slice(0, 3))
            }}
            onFocus={(value) => value.target.value && setValue("percentage", value.target.value.replaceAll(" %", ""))}
            onBlur={(value) => value.target.value && setValue("percentage", value.target.value + " %")}
          />
        )}
      />
      <small className="text-danger pt-2">
        {errors?.percentage?.message}
      </small>
    </Col>

    <Col sm='12' className="mb-3 custom-form-input form-input">
      <Label className="text-14 text-blackele pb-2">Note <span className="text-danger">*</span></Label>
      <Controller
        name="note"
        rules={{ required: { value: true, message: "Note is a required field" } }}
        control={control}
        render={(field) => (
          <Input
            {...field}
            defaultValue={commissionOverwrite?.note}
            innerRef={register}
            placeholder="Enter note"
            type="text"
            onChange={(item) => field.onChange(item.target.value)}
          />
        )}
      />
      <small className="text-danger pt-2">
        {errors?.note?.message}
      </small>
    </Col>

  </Row >
}
export default CommissionOverwriteForm
import { FC } from "react"
import { RecruiterDealFormProps } from "../../../../interfaces"
import BasicInformation from "./BasicInformation"
import CommissionDeal from "./commission-deals"


const RecruiterDealForm: FC<RecruiterDealFormProps> = ({ recruiterDeal, setNonRecoverable, nonRecoverable,
  underTraining, setUnderTraining, getValues, control, errors, register, setValue, tiers, refetchDeals }) => {
  return <>
    <BasicInformation getValues={getValues} recruiterDeal={recruiterDeal} setNonRecoverable={setNonRecoverable} setUnderTraining={setUnderTraining}
      underTraining={underTraining} control={control} errors={errors} nonRecoverable={nonRecoverable}
      register={register} setValue={setValue} />
    <CommissionDeal refetchDeals={refetchDeals} tiers={tiers || []} errors={errors} control={control}
      register={register} setValue={setValue} />
  </>
}
export default RecruiterDealForm
import { useLazyQuery } from "@apollo/client";
import moment from "moment";
import { FC, useCallback, useEffect, useState } from "react";
import { Badge } from "reactstrap";
import { DealHistoryProps, IRecruiterDealHistory } from "../../../../interfaces";
import { PAGE_LIMIT_MIN } from "../../../../utils/constant";
import InputField from "../../../layout/InputField";
import Loader from "../../../layout/Loader";
import NoDataFound from "../../../layout/NoDataFound";
import Pagination from "../../../layout/Pagination";
import TableComponent from "../../../layout/TableComponent";
import { GET_RECRUITER_DEAL_TIER_CHANGE_HISTORY } from "../gql";

const DealHistory: FC<DealHistoryProps> = ({ dealId }) => {
  const [recruiterDealHistory, setRecruiterDealHistory] = useState<IRecruiterDealHistory[]>()
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(3);

  const [getHistoryQuery, { data, loading, }] = useLazyQuery(GET_RECRUITER_DEAL_TIER_CHANGE_HISTORY, {
    onCompleted: (val) => setRecruiterDealHistory(val?.getRecruiterDealTierHistory?.recruiterDealHistory)
  })

  const getHistory = useCallback(async () => {
    await getHistoryQuery({
      variables: {
        dealId, paginationInput: { limit, page: currentPage }
      },
    });
  }, [currentPage, limit, getHistoryQuery]);

  useEffect(() => {
    getHistory();
  }, [getHistory]);

  return <>
    <div className="fw-700 fs-14 my-4">{"Deal Change (History)"}</div>

    <TableComponent textCenter={true} tableHeader={['Deal Change', 'Date', 'commission override']}>
      <tbody>
        {!loading && !!recruiterDealHistory?.length && recruiterDealHistory.map(({ tierType, tierUpdated, updatedAt, tierOverrideType }) => <tr>
          <td className="text-center">Recruiter promoted to <div className="d-inline font-weight-bold">{`${tierType} Commission Tier ${tierUpdated}`}</div></td>
          <td className="text-center">{moment(updatedAt).format('MM/DD/YYYY')}</td>
          <td className="text-center">
            <Badge className="text-capitalize" color={tierOverrideType === 'automatic' ? "success" : "info"}>
              {tierOverrideType}
            </Badge>
          </td>
        </tr>
        )}
        {!loading && !recruiterDealHistory?.length &&
          <td colSpan={5} className="empty-table-td">
            <NoDataFound text="No Deal Change History Found!" />
          </td>}
        {!!loading && <td colSpan={5} className="empty-table-td">
          <Loader loaderClass="xs-height " />
        </td>}
      </tbody>
    </TableComponent>

    {(!loading && !!recruiterDealHistory?.length) &&
      <div className="users-pagination py-1" style={{ border: "1px solid #E5E7EB" }}>
        <Pagination
          onPageChange={(pageClicked: number) => {
            setCurrentPage(pageClicked);
          }} currentPage={currentPage}
          pageCount={Math.ceil(data?.getRecruiterDealTierHistory?.count / limit)}
        />
        <div className="d-flex align-items-center">
          <div className="pagination-display paginationItemsSelect">
            <p className="m-0">
              {(currentPage - 1) * limit + 1} to{' '}
              {Math.min(
                currentPage * limit,
                data?.getRecruiterDealTierHistory?.count
              )}
              {` of `} {data?.getRecruiterDealTierHistory?.count}
            </p>

            <div className="divider"></div>
            <label>Display</label>
            <InputField
              label=""
              inputtype="select"
              selectItems={PAGE_LIMIT_MIN.filter((item) => item.value !== limit)}
              inputid="limit"
              placeholder={limit.toString()}
              inputMethod={(value) => {
                setCurrentPage(1);
                setLimit(parseInt(value));
              }}
            />
          </div>
        </div>
      </div>}
  </>
}
export default DealHistory
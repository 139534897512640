import { FC } from "react"
import { RecruiterDetailStatsProps } from "../../../../interfaces"
import { VIEW_RECRUITER_DEAL_DETAIL_ENTRIES } from "../../../../utils/constant"

const DetailStats: FC<RecruiterDetailStatsProps> = ({ detailStats }) => {
  return <div className='w-400 fs-13' >
    <div className="d-flex align-items-center justify-content-between my-2">
      <div className='color-gray'>
        {VIEW_RECRUITER_DEAL_DETAIL_ENTRIES.map((value, index) =>
          !(value === 'Mentor Commission' && !detailStats[3]) && (
            <div key={index} className='my-2'>{value}</div>
          )
        )}
      </div>
      <div>
        {detailStats.map((value) => <div className='my-2'>{value}</div>)}
      </div>
    </div>
  </div>
}
export default DetailStats
// packages block
import axios from 'axios';
import FileDownload from 'js-file-download';
import { toast } from 'react-toastify';
// constants block
import moment from 'moment';
import { CreateRecruiterDealInput, ICommissionTiers, IRecruiterDealTier, SelectorOptionType } from '../interfaces';
import {
  ACCOUNTING_BOARD,
  AWAITING_EXECUTIVE_APPROVAL,
  AWAITING_MANAGER_APPROVAL,
  AggreementType,
  AgreementStatus,
  CLIENT_PREVIEW,
  CLIENT_REJECTED,
  COMPANY_STATUS,
  ChatGPTTemplate,
  CompanySource,
  DANGER,
  DRAFT,
  EXECUTIVE_APPROVED,
  EXECUTIVE_REJECTED,
  FAILED_IMPORT,
  INFO,
  JobOrderStatusForHeader,
  MANAGER_REJECTED,
  MONTHS,
  OUT_FOR_SIGNATURE,
  PAYMENT_RECEIVED,
  PENDING_EXECUTIVE_APPROVAL,
  PLACEMENT_TYPE,
  PRIMARY,
  RESENT,
  ROUTE_HISTORY_KEY,
  SIGNED,
  SUCCESS,
  SUCCESS_IMPORT,
  UNDELIVERABLE,
  VOIDED,
  candidateNotesCategories,
  companyNotesCategories,
  contactNotesCategories,
  interviewDetailsNotesCategories,
  jobApplicantNotesCategories,
  jobNotesCategories,
  offerCategoryNotes,
  placementManagement,
  sortingAlphabetical,
  submissionNoteCategories,
} from './constant';

// this url is for outlook redeirect uris need to convert into .env file
export const localhostRedirectURI = 'http://localhost:3000/settings';
export const outlookRedirectURI =
  process.env.REACT_APP_OUTLOOK_REDIRECT_URI ||
  'https://stage.ats.realrepp.com/settings';
export const productionRedirectURI = 'https://ats.realrepp.com/settings';

// this url is for Docusign redeirect uris need to convert into .env file
export const docuSignRedirectURI =
  process.env.REACT_APP_DOCU_SIGN_CALLBACK_URL ||
  'http://localhost:3000/docusign/callback';
export const docusignURLForCode =
  process.env.REACT_APP_DOCU_SIGN_CODE ||
  'https://account-d.docusign.com/oauth/auth';
export const DocusignExecutiveEmail =
  process.env.REACT_APP_DOCUSIGN_EXECUTIVE_EMAIL || 'petertan@realrepp.com';

console.log('DocusignExecutiveEmail....1', DocusignExecutiveEmail);

export const docusignIKey =
  process.env.REACT_APP_DOCU_SIGN_CLIENT_ID ||
  '0aa7af44-1fc7-4c9e-b7f6-2ae570a4927a';

// Uploading
export const uploadImage = async (
  file: File | any,
  folderName: string,
  driveUpload = false,
  convertPdf = true,
  publicBucket = false
) => {
  try {
    const formData = new FormData();
    formData.append('file', file, `${file.name}`);
    formData.append('driveUpload', `${driveUpload}`);
    formData.append('folderName', `${folderName}`);
    formData.append('convertPdf', `${convertPdf}`);
    formData.append('publicBucket', `${publicBucket}`);

    const apiBaseURL =
      process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001';
    let response: any = await fetch(`${apiBaseURL}/file-upload`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
    });
    response = await response.json();
    return response;
  } catch (error) {
    throw error;
  }
};

export const uploadFile = async (
  file: File | any,
  folderName: string,
  driveUpload = false,
  type: string
) => {
  try {
    const formData = new FormData();
    formData.append('file', file, `${file.name}`);
    formData.append('driveUpload', `${driveUpload}`);
    formData.append('folderName', `${folderName}`);
    const apiBaseURL =
      process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001';
    let response: any = await fetch(`${apiBaseURL}/file-upload/${type}`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
    });
    response = await response.json();
    return response;
  } catch (error) {
    throw error;
  }
};

export const downloadDocxFile = async (url: string, fileName: string) => {
  try {
    const apiBaseURL =
      process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001';
    const response = await axios.post(
      `${apiBaseURL}/file-upload/downloadDocx`,
      {
        url,
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.token,
        },
        responseType: 'blob',
      }
    );
    FileDownload(response.data, fileName);
  } catch (error) {
    toast.error('Error while downloading file.');
  }
};

export const validateCSVbyHeaders = (mainHeaders: string[], headersToValidate: string[]): boolean => {
  if (mainHeaders.length === headersToValidate?.length) {
    const filteredValidationHeaders = headersToValidate.map((item) => item.replace(/["'\r]/g, '').trim())
    const filteredMainHeaders = mainHeaders.map((item) => item.replace(/["'\r]/g, '').trim())
    return filteredMainHeaders.every((element, index) => element === filteredValidationHeaders[index])
  }
  return false
}

export const downloadDocxForPreView = async (url: string, fileName: string) => {
  try {
    const apiBaseURL =
      process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001';
    const response = await axios.post(
      `${apiBaseURL}/file-upload/downloadDocx`,
      {
        url,
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.token,
        },
        responseType: 'blob',
      }
    );
    var file = new Blob([response.data], { type: 'application/pdf' });
    var fileURL = URL.createObjectURL(file);
    return fileURL;
    // window.open(fileURL);
    // FileDownload(response.data, fileName);
  } catch (error) {
    toast.error('Error while downloading file.');
  }
};

export const resumeParse = async (file: File | any, isPrivateCandidate: boolean) => {
  try {
    const formData = new FormData();
    formData.append('file', file, `${file.name}`);
    formData.append('lastModifyDate', `${file?.lastModifiedDate}`);
    const apiBaseURL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001';
    const apiUrl = isPrivateCandidate ? `${apiBaseURL}/file-upload/resume/parse-upload?isPrivate=true` : `${apiBaseURL}/file-upload/resume/parse-upload?isPrivate=false`
    let response: any = await fetch(apiUrl, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
    });
    response = await response.json();
    return response;
  } catch (error) {
    throw error;
  }
};

export const removeExtraDots = (value: string) => {
  if (!value?.includes('.')) return value;
  const arr = value?.split('.');
  return `${arr[0]}.${arr?.slice(1).join('').substring(0, 2)}`;
};

export const moneyFieldHandler = (
  value: string,
  setValue: Function,
  name: string
) => {
  if (value) {
    if (value?.indexOf('.') !== value?.lastIndexOf('.')) {
      setValue(name, value?.substring(0, value?.length - 1));
      return;
    }

    if (value?.includes('.')) {
      if (value?.length - 4 !== value.lastIndexOf('.')) {
        setValue(name, value);
      } else {
        setValue(name, value.substring(0, value?.length - 1));
      }
      return;
    }

    if (value.includes(',')) {
      const parsedStringFromComma = +value.replaceAll(',', '');
      setValue(name, parsedStringFromComma.toLocaleString('en-US'));
    } else {
      const parsedValue = parseFloat(value);
      setValue(name, parsedValue.toLocaleString('en-US'));
    }
  }
};

export const checkingIsNaN = (value: string): boolean => {
  const parseToFloat = parseFloat(value);
  if (isNaN(parseToFloat)) {
    return false;
  }
  return true;
};

export const moneyFormatter = (AMOUNT: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(AMOUNT);
};

export const instagramHandlerToLink = (instaHandler: string) => {
  const removingAtTheRate = instaHandler?.substring(1);
  return `https://www.instagram.com/${removingAtTheRate}`;
};

export const commaRemover = (VALUE: string) => {
  return VALUE?.includes(',')
    ? +removeExtraDots(VALUE)?.replaceAll(',', '')
    : parseFloat(removeExtraDots(VALUE)) || 0;
};

export const localStringMaker = (VALUE: number) => {
  return (VALUE ? VALUE : 0)?.toLocaleString('en-US', {
    minimumFractionDigits: 2,
  });
};

export const priceHandler = (value: string) => {
  if (value) {
    if (value?.indexOf('.') !== value?.lastIndexOf('.')) {
      return value?.substring(0, value?.length - 1);
    }

    if (value?.includes('.')) {
      if (value?.length - 4 !== value.lastIndexOf('.')) {
        return value;
      } else {
        return value.substring(0, value?.length - 1);
      }
    }

    if (value.includes(',')) {
      const parsedStringFromComma = +value.replaceAll(',', '');
      return parsedStringFromComma.toLocaleString('en-US');
    } else {
      const parsedValue = parseFloat(value);
      return parsedValue.toLocaleString('en-US');
    }
  }
};

export const countWordsInString = (
  str: string,
  WORD_LENGTH: number
): boolean => {
  return str.trim().split(/\s+/).length > WORD_LENGTH;
};

export const trimStringUptoWords = (
  str: string,
  WORD_LENGTH: number
): string => {
  return str.trim().split(/\s+/).slice(0, WORD_LENGTH).join(' ') + '...';
};

export const jobDescriptionHandler = (
  description: string,
  hasBoth: boolean
) => {
  let desc = description;
  if (hasBoth) {
    return (desc = `${desc} <p></br><em style="word-break: normal;white-space: normal; color:black">*We are an equal opportunity employer and value diversity at our company. We do not discriminate on the basis of race, religion, color, national origin, gender, sexual orientation, age, marital status, veteran status, or disability status.*</em></p> </br>  <p><em style="word-break: normal;white-space: normal; color:black">*Pursuant to the San Francisco Fair Chance Ordinance, we will consider for employment qualified applicants with arrest and conviction records.*</em></p>`);
  } else {
    return (desc = `${desc} <p></br><em style="word-break: normal;white-space: normal;color:black">*We are an equal opportunity employer and value diversity at our company. We do not discriminate on the basis of race, religion, color, national origin, gender, sexual orientation, age, marital status, veteran status, or disability status.*</em></p>`);
  }
};

export const getClassNameForTableBase = (active: boolean, isFirst: boolean, isLast: boolean) => {
  if (active) {
    if (isFirst && isLast)
      return 'radius-top-table radius-bottom-table active-table-row py-2'
    if (isFirst)
      return 'radius-top-table active-table-row py-2'
    if (isLast)
      return 'radius-bottom-table active-table-row py-2'
    return 'active-table-row py-2'
  }
  else {
    if (isFirst && isLast)
      return 'radius-top-table radius-bottom-table table-row py-2 inactive-table-row'
    if (isFirst)
      return 'radius-top-table table-row py-2 inactive-table-row'
    if (isLast)
      return 'radius-bottom-table table-row py-2 inactive-table-row'
    return 'table-row py-2 inactive-table-row'
  }
}

export const formatDealInput = (recruiterId: string, nonRecoverableDraw: boolean, recruiterInTraining: boolean, value): CreateRecruiterDealInput => {
  const { dealEndDate, assignedManagerId, mentorCommission, trainingCompletionDate, tempTiers, directHireTiers, ...rest } = value
  let recruiterDealTiers: IRecruiterDealTier[] = []
  !!directHireTiers?.length && recruiterDealTiers.push(...directHireTiers?.map(({ startRange, endRange, tierId, ...rest }, index) => {
    return { startRange: startRange?.value, endRange: endRange?.value, tierType: 'DIRECT_HIRE', tierNo: (index + 1).toString(), id: tierId, ...rest }
  }))
  !!tempTiers?.length && recruiterDealTiers.push(...tempTiers?.map(({ startRange, endRange, tierId, ...rest }, index) => {
    return { startRange: startRange?.value, endRange: endRange?.value, tierType: 'TEMP', tierNo: (index + 1).toString(), id: tierId, ...rest }
  }))

  return {
    ...rest,
    period: dealEndDate ? "0-90 days" : '90+ days',
    mentorCommission: recruiterInTraining ? mentorCommission : '',
    assignedManagerId: recruiterInTraining ? assignedManagerId?.value : null,
    trainingCompletionDate: trainingCompletionDate?.value,
    recruiterId, dealEndDate, nonRecoverableDraw, recruiterDealTiers, recruiterInTraining
  }

}

export const notificationJsonTruth = (
  notifyableType: string,
  alertType: string
): boolean => {
  return (
    (notifyableType === 'Candidate' &&
      alertType === 'CANDIDATE_CHECKUP_REMINDER') ||
    alertType === 'PLACEMENT_REMINDER' ||
    (alertType === 'INTERVIEW_INVITE_CHECKUP' &&
      notifyableType === 'InviteCheckup') ||
    (alertType === 'CLIENT_CHECKUP_REMINDER' &&
      notifyableType === 'JobOrder') ||
    (notifyableType === 'Agreement' &&
      alertType === 'SEND_AGREEMENT_CHECKUP') ||
    (notifyableType === 'Agreement' &&
      alertType === 'INFORMING_AGREEMENT_CHECKUP') ||
    (notifyableType === 'OwnershipCompanyExpirationReport' &&
      alertType === 'OWNERSHIP_EXPIRATION') ||
    (notifyableType === 'Placement' &&
      alertType === 'PLACEMENT_CANCELLATION_REQUEST')
  );
};

export const countSummaryWords = (str) => {
  str = str.replace(/(^\s*)|(\s*$)/gi, '');
  str = str.replace(/[ ]{2,}/gi, ' ');
  str = str.replace(/\n /, '\n');
  return str.split(' ').length;
};

export const userReplyAccess = (data, user) => {
  let toAddress = data?.toAddress?.length ? data?.toAddress : [];
  let ccAddress = data?.ccAddress?.length ? data?.ccAddress : [];
  let bccAddress = data?.bccAddress?.length ? data?.bccAddress : [];

  let allMails = [...toAddress, ...ccAddress, ...bccAddress, data?.fromAddress];

  return allMails?.includes(user?.email);
};

export const getCompanyOwnerFromCompany = (company) => {
  let owner = company?.ownerAndSharees?.find(
    (item) => item.ownerType === 'OWNER'
  );
  return owner?.owner;
};

// this function is used for the making location string from the company location
export const makingLocationString = (data) => {
  return `${data?.streetAddress ?? ''}${data?.streetAddress ? ',' : ''}${data?.city ?? ''
    }${data?.city ? ',' : ''}${data?.state ?? ''}`;
};

//
export const amountPrettifier = (item: string) => {
  const value = Number(item).toLocaleString('en-US')
  return Number(item) >= 0 ? `$ ${value}` : `($ ${value.slice(1)})`
}


// this function is used for the admin or exective permission check
export const adminExecAMCheck = (applicant, user, userRoles): boolean => {
  let companyOwnerShareIds = applicant?.JobOrder?.company?.ownerAndSharees?.map(
    (item) => item?.owner?.id
  );
  const movedToOffers = applicant?.interviews?.find(
    (item: { interviewStatus: string }) =>
      item.interviewStatus === 'MOVE_TO_OFFER'
  );
  return !movedToOffers &&
    (companyOwnerShareIds?.includes(user?.id) ||
      userRoles?.includes('ADMIN') ||
      userRoles?.includes('EXECUTIVE'))
    ? true
    : false;
};

export const location = (data) => {
  return ['US', null, undefined, ''].includes(data?.country) ? `${data?.streetAddress ?? ''}${data?.streetAddress ? ', ' : ''}${data?.city ?? ''}${data?.city ? ', ' : ''}${data?.state ?? ''} ${data?.state ? ", " : ''} ${data?.zipCode ?? ''}` : `${data?.streetAddress ?? ''}${data?.streetAddress ? ', ' : ''}${data?.city ?? ''}${data?.city ? ', ' : ''}${data?.zipCode ?? ''}${data?.zipCode ? ', ' : ''}${data?.country ?? ''}`;
};

export const getAgreementColor = (status: string) => {
  switch (status) {
    case AWAITING_MANAGER_APPROVAL:
      return INFO;

    case AWAITING_EXECUTIVE_APPROVAL:
    case PENDING_EXECUTIVE_APPROVAL:
      return 'secondary';

    case EXECUTIVE_APPROVED:
      return SUCCESS;

    case OUT_FOR_SIGNATURE:
    case SIGNED:
    case RESENT:
      return 'warning';

    case DRAFT:
    case CLIENT_PREVIEW:
      return 'primary';

    case MANAGER_REJECTED:
    case EXECUTIVE_REJECTED:
    case CLIENT_REJECTED:
    case VOIDED:
    case UNDELIVERABLE:
      return DANGER;

    default:
      return '';
  }
};

export const getInvoiceStatusColor = (status: string) => {
  switch (status) {
    case PAYMENT_RECEIVED:
      return SUCCESS;

    default:
      return PRIMARY;
  }
};

export const getHistoryKey = (key: string) => {
  switch (key) {
    case 'dealName':
      return 'Deal Name';

    case 'recruiterCommissionMentor':
      return 'Mentor Commission';

    case 'nonRecoverableDraw':
      return 'Non Recoverable Draw';

    case 'status':
      return 'Status ';

    case 'dealStartDate':
      return 'Deal Start Date';

    case 'dealEndDate':
      return 'Deal End Date';

    case 'recruiterInTraining':
      return 'Recruiter In Training';

    case 'assignedManager':
      return 'Assigned Manager';

    case 'mentorCommission':
      return 'Mentor Commission';

    case 'trainingCompletionDate':
      return 'Training Completion Date';

    case 'contractingCommission':
      return 'Contracting Commission';

    case 'drawPercentage':
      return 'draw Percentage';

    case 'contractingBaseRate':
      return 'contracting BaseRate';

    case 'biWeeklySalary':
      return 'Bi Weekly Salary';

    case 'dhBaseRate':
      return 'DH Base Rate';
  }
};

export const getCSVImportsStatusColor = (status: string) => {
  switch (status) {
    case SUCCESS_IMPORT:
      return SUCCESS;

    case FAILED_IMPORT:
      return DANGER;
    default:
      return PRIMARY;
  }
};

export const getRequestColor = (status: string) => {
  switch (status) {
    case 'DECLINED':
      return DANGER;

    case 'APPROVED':
      return SUCCESS;

    case 'PENDING':
      return INFO;
  }
};

export const formatValue = (text: string) => {
  let formatted = '';

  if (!!text && !!text.length) {
    text
      ?.split('_')
      .map(
        (term) =>
        (formatted = `${formatted} ${term.charAt(0).toUpperCase()}${term
          .slice(1)
          .toLowerCase()}`)
      );
  }

  return formatted?.trim();
};

export const mapEnum = (enumerable) => {
  if (enumerable) {
    let enumMembers = Object.keys(enumerable).map(
      (key) => (enumerable as any)[key]
    );

    return enumMembers.map((member) => {
      return {
        value: member?.id,
        label: member?.fullName?.trim(),
      };
    });
  } else return [];
};

export const filterNoteCategories = (type: string) => {
  switch (type) {
    case 'Candidate':
      return sortingAlphabetical(candidateNotesCategories);

    case 'Job Order':
      return sortingAlphabetical(jobNotesCategories);

    case 'Contact':
      return sortingAlphabetical(contactNotesCategories);

    case 'Company':
      return sortingAlphabetical(companyNotesCategories);

    case 'Interview':
      return sortingAlphabetical(interviewDetailsNotesCategories);

    case 'Offer':
      return sortingAlphabetical(offerCategoryNotes);

    case 'Submission':
      return sortingAlphabetical(submissionNoteCategories);

    default:
      return sortingAlphabetical(jobApplicantNotesCategories);
  }
};

const generateGreetings = (): string => {
  let currentHour = moment().format('HH');

  if (currentHour >= '0' && currentHour <= '11') {
    return 'Good morning';
  } else if (currentHour >= '12' && currentHour <= '17') {
    return 'Good afternoon';
  } else if (currentHour >= '18' && currentHour <= '23') {
    return 'Good evening';
  } else {
    return '';
  }
};

export const bodyDefaultData = (
  contactName: string,
  companyName: string,
  type: string
): string => {
  return `Dear ${contactName},

${!!generateGreetings() ? generateGreetings() + ', w' : 'W'
    }e appreciate the opportunity to work with ${companyName} and look forward to great success working together.

Attached is the ${type} Agreement for your review. Please let us know if you have any questions.

In the event of a different contract signatory, please reply and Cc your RealREPP contact with the details for the new signatory. Please include First, Last Name, Title, Email Address and we will send a new agreement asap.

We look forward to working with you!

Stay Safe + Healthy
Cheers,
Johnny Renaudo
CEO | Founder`;
};

export const getUserStatusColor = (status: string) => {
  switch (status) {
    case 'ACTIVE':
      return 'success';

    case 'ON_LEAVE':
      return 'warning';

    case 'SUSPENDED':
      return 'primary';

    case 'TERMINATED':
      return 'danger';

    case 'NEW_HIRE':
      return 'info';

    default:
      return '';
  }
};

export const getCompanyStatusColor = (status: string) => {
  switch (status) {
    case 'ACTIVE':
      return 'success';

    case 'PROSPECT':
      return 'warning';

    case 'DRAFT':
      return 'primary';

    case 'DO_NOT_CONTACT':
      return 'danger';

    case 'ACTIVE_AGREEMENT':
      return 'info';

    default:
      return '';
  }
};

export const setSelectOption = (
  value: string,
  label: string = ''
): SelectorOptionType => {
  return { value, label };
};

export const updateFileName = (name: string) => {
  const nameParts = name.split('.')
  let fileName = '';

  for (let index in nameParts) {
    if (parseInt(index) === nameParts.length - 1) {
      fileName = fileName.concat(`.${nameParts[index].toLowerCase()}`)
    } else {
      fileName = fileName.concat(`${nameParts[index]}`)
    }
  }

  return fileName
}

export const getInterviewNumber = (interviewIndex: number): string => {
  switch (interviewIndex) {
    case 1:
      return '1st';

    case 2:
      return '2nd';

    case 3:
      return '3rd';

    default:
      return `${interviewIndex}th`
  }
}

export const checkInterviewExpiration = (interviewDateTime: string, timeZoneId: string): boolean => {
  const now = moment().utcOffset(timeZoneId)
  const time = moment(interviewDateTime).utcOffset(timeZoneId)
  const isExpired = time.diff(now, 'minutes') < 0

  return isExpired
}
export const defaultDtaeForCalender = () => {
  const now = new Date();
  const offset = now.getTimezoneOffset() * 60000;
  const adjustedDate = new Date(now.getTime() - offset);
  return moment(adjustedDate).format('YYYY-MM-DD');
}
export const maxDate = () => {
  const today = new Date();
  return today.toISOString().split('T')[0];
}

export const destroyRouteHistory = () => {
  localStorage.removeItem(ROUTE_HISTORY_KEY)
}

export const sortingAlphabeticalAndRemoveDuplicateEntities = (arr) => {
  const seenEntities = new Set();
  return arr
    .sort((a, b) => a.label.localeCompare(b.label))
    .filter((entity) => {
      const isDuplicate = seenEntities.has(
        `${entity.label}:${entity.value}`
      );
      if (!isDuplicate) {
        seenEntities.add(`${entity.label}:${entity.value}`);
      }
      return !isDuplicate;
    });
};

export const pushInHistory = (route: string, searchParams: string = '') => {
  const history = localStorage.getItem(ROUTE_HISTORY_KEY);
  let routes = !!history?.length ? JSON.parse(history) : [];
  routes = Array.isArray(routes) ? routes : [routes];

  routes.push(!!searchParams ? `${route}${searchParams}` : route)
  localStorage.setItem(ROUTE_HISTORY_KEY, JSON.stringify(routes))
}

export const popFromHistory = (): string => {
  const history = localStorage.getItem(ROUTE_HISTORY_KEY);
  let routes = !!history?.length ? JSON.parse(history) : [];
  routes = Array.isArray(routes) ? routes : [routes];

  const backUrl = routes.pop()
  localStorage.setItem(ROUTE_HISTORY_KEY, JSON.stringify(routes))

  return !!backUrl ? backUrl : ''
}

export const countryChecker = (countryCode) => {
  return ['US', null, undefined, ''].includes(countryCode)
}

export const countryWiseHeaderLocation = (countryCode, city, stateCode) => {
  switch (countryCode) {
    case 'UK':
      return `${city ? city : "--"}, ${countryCode ? countryCode : "--"}`
    case "CA":
      return `${city ? city : "--"}, ${stateCode ? stateCode : "--"}, ${countryCode ? countryCode : "--"}`
    case "MX":
      return `${city ? city : "--"}, ${stateCode ? stateCode : "--"}, ${countryCode ? countryCode : "--"}`
    default:
      return `${city ? city : "--"}, ${stateCode ? stateCode : "--"}`
  }
}

export const countryWiseDetailedlocation = (countryCode, city, stateCode, streetAddress, postalCode, address1 = '', address2 = '') => {
  switch (countryCode) {
    case 'UK':
      return `${streetAddress ? streetAddress : "--"}, ${city ? city : "--"}, ${postalCode ? postalCode : "--"}, ${countryCode ? countryCode : "--"}`
    case "CA":
      return `${streetAddress ? streetAddress : "--"}, ${city ? city : "--"}, ${stateCode ? stateCode : "--"}, ${postalCode ? postalCode : "--"}, ${countryCode ? countryCode : "--"}`
    case "MX":
      return `${address1 ? address1 : "--"}, ${address2 ? address2 : "--"}, ${city ? city : "--"}, ${stateCode ? stateCode : "--"}, ${postalCode ? postalCode : "--"}, ${countryCode ? countryCode : "--"}`
    default:
      return `${streetAddress ? streetAddress : "--"}, ${city ? city : "--"}, ${stateCode ? stateCode : "--"}, ${postalCode ? postalCode : "--"}`
  }
}

export const placeHolderForStatus = (placeHolder, fromPlaceHolder) => {
  if (placeHolder.includes("STATUS") || placeHolder.includes("Status")) {
    return fromPlaceHolder ? "Status" : "STATUS";
  } else {
    return placeHolder;
  }
}

export const selecionOfInputFieldInHeader = (
  type: string,
  tableName: string
) => {
  switch (type) {
    case 'Place Status':
      return tableName === 'Placement'
        ? placementManagement
        : ACCOUNTING_BOARD;

    case 'month':
      return MONTHS;

    case 'Type':
      return tableName === 'AllAgreements' ? AggreementType : PLACEMENT_TYPE;
    case 'Status':
      return COMPANY_STATUS;
    case 'Candidate Status':
      return [
        { label: 'Active', value: 'ACTIVE' },
        { label: 'Do Not Contact', value: 'DO_NOT_CONTACT' },
      ];
    case 'Posted':
      return [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
      ];

    case 'Source':
      return CompanySource;

    case 'Contact Status':
      return [
        { label: 'Active', value: 'ACTIVE' },
        { label: 'Do Not Contact', value: 'DO_NOT_CONTACT' },
        { label: 'Draft', value: 'DRAFT' },
      ];;

    case 'Job Status':
      return JobOrderStatusForHeader?.filter(
        (item) => item.value !== 'MY_JOB_ORDERS'
      );

    case 'Agreement Status':
      return AgreementStatus.filter(
        (item) =>
          item.value !== 'MY_AGREEMENTS' &&
          item.value !== 'MY_REJECTED_AGREEMENTS' &&
          item.value !== 'MY_PENDING_AGREEMENTS'
      );
  }
};

export const numericValueHandler = (input: string): boolean => {
  return /^[0-9.-]+$/.test(input);
}

export const findIndex = (stringArray, targetString) => {
  const index = stringArray.indexOf(targetString);
  return index !== -1 ? index : -1; // Return index if found, otherwise -1
}


export const widthForDropDown = (label: string, pageNanme: string) => {
  switch (pageNanme) {
    case 'Companies':
      return { minWidth: '140px' };
    case 'AllAgreements':
      return label === 'TYPE' ? { minWidth: '163px' } : { minWidth: '199px' };
    case 'JobOrder':
      return label === 'POSTED' ? { minWidth: '80px' } : { minWidth: '131px' };
    case 'Placement':
      return label === 'TYPE' ? { minWidth: '98px' } : { minWidth: '162px' };
    case 'Accounting':
      return label === 'TYPE' ? { minWidth: '98px' } : { minWidth: '162x' };
    case 'Contacts':
      return { minWidth: '125px' };
    case 'Candidate':
      return { minWidth: '126px' };
  }
};

export const chatGptJobOrderTemplates = (promptId: number): string => {
  switch (promptId) {
    case 0:
      return 'MUST_HAVE_SKILS';
    case 1:
      return 'GOOD_HAVE_SKILS';
    case 2:
      return 'INTERVIEW_QUESTIONS';
    default:
      return 'INTERVIEW_QUESTIONS';
  }
};



export const getTemplateByModule = (templates: ChatGPTTemplate[], moduleName: string): string | null => {
  const matchedTemplate = templates.find(template => template.module === moduleName);
  return matchedTemplate ? matchedTemplate.template : null;
}


export const replacePlaceholderWithResume = (template, resumeContent, tag): string => {
  if (template) {
    return template.replace(tag, resumeContent);
  }
  return template;
}
import { FC } from "react"
import { CommissionDealProps } from "../../../../../interfaces"
import CommissionTiers from "./CommissionTiers"
import { TierType } from "../../../../../constants"

const CommissionDeal: FC<CommissionDealProps> = ({ register, refetchDeals, control, tiers, errors, setValue }) => {

  return <>
    <CommissionTiers refetchDeals={refetchDeals} tierType="directHireTiers" control={control} errors={errors} register={register} setValue={setValue}
      tiers={tiers?.filter(({ tierType }) => tierType === TierType.DIRECT_HIRE)} />
    <CommissionTiers refetchDeals={refetchDeals} tierType="tempTiers" control={control} errors={errors} register={register} setValue={setValue}
      tiers={tiers?.filter(({ tierType }) => tierType === TierType.TEMP)} />
  </>
}
export default CommissionDeal
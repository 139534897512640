import { useLazyQuery, useMutation } from "@apollo/client";
import { FC, useState } from "react";
import { toast } from "react-toastify";
import { Badge, Collapse, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner } from "reactstrap";
import VERTICAL_DOTS_FILLED from '../../../../assets/images/vertical-dots-filled.svg';
import VERTICAL_DOTS from '../../../../assets/images/vertical-dots.svg';
import { DELETE } from "../../../../constants";
import { DealsProps, IRecruiterDeal, IRecruiterDealHistory } from "../../../../interfaces";
import { formatDateUSWithMoment } from "../../../../utils/commonFn";
import { EDIT, SET_ACTIVE, VIEW, VIEW_HISTORY } from "../../../../utils/constant";
import { getClassNameForTableBase } from "../../../../utils/helper";
import ConfirmPopUp from "../../../ConfirmPopUp";
import { ACTIVATE_DEAL, DELETE_RECRUITER_DEAL, GET_RECRUITER_DEAL_CHANGE_HISTORY } from "../gql";
import NoDataFound from "../../../layout/NoDataFound";
import DealsHistoryStepper from "./DealsHistoryStepper";

const Deals: FC<DealsProps> = ({ setRecruiterDeal, setRecruiterDealId, setEditDrawerOpen, refetch, recruiterDeals, toggleViewDetail }) => {
  const [collapse, setCollapse] = useState({});
  const [menu, setMenu] = useState({})
  const [deletePopUp, setDeletePopUp] = useState<boolean>(false)
  const [dealName, setDealName] = useState<string>('')
  const [dealId, setDealId] = useState<string>('')
  const [activateDealPopUp, setActivateDealPopUp] = useState<boolean>(false)
  const [dealHistory, setDealHistory] = useState<IRecruiterDealHistory[]>()

  //mutations and Queries
  const [activateDeal, { loading }] = useMutation(ACTIVATE_DEAL, {
    onCompleted: () => {
      toast.success("Recruiter Deal Activated Successfully")
      refetch()
    },
    onError: () => toast.error("Unable to Activate Deal")
  })

  const [deleteDeal, { loading: deleting }] = useMutation(DELETE_RECRUITER_DEAL, {
    onCompleted: () => {
      toast.success("Recruiter Deal Deleted Successfully")
      refetch()
    },
    onError: () => toast.error("Unable to Delete this Deal")
  })

  const [getDealHistory, { loading: fetchingHistory }] = useLazyQuery(GET_RECRUITER_DEAL_CHANGE_HISTORY, {
    onCompleted: (data) => {
      setDealHistory(data?.getRecruiterDealHistory?.recruiterDealHistory)
    }
  })

  const toggleCollapse = (index: number) => {
    setCollapse({
      [index]: !collapse[index]
    });
  };

  const toggleMenu = (index: number) => {
    setMenu((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const viewDetail = (item: IRecruiterDeal) => {
    setRecruiterDeal(item)
    setRecruiterDealId(item?.id)
    toggleViewDetail()
  }

  const editDetail = (item: IRecruiterDeal) => {
    setRecruiterDeal(item)
    setRecruiterDealId(item?.id)
    setEditDrawerOpen(true)
  }

  return <div className="custom-table">
    <table className="custom-table my-4" style={{
      width: "100%",
      borderSpacing: 10,
      borderCollapse: "separate",
    }}>
      <thead className="table-header py-2">
        <tr className="table-row" style={{ borderRadius: 6, boxShadow: "#F7F8F9 0px 0px 0px 1px" }}>
          {['DEAL NAME', 'CREATED ON', 'UPDATED ON', 'STATUS', 'ACTIONS'].map((key) => (
            <th key={key} className="table-header-cell table-header">
              {key.toLocaleUpperCase()}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="table-body">
        {recruiterDeals.map((item, index) => (
          <>
            <tr key={index} onClick={() => {
              if (collapse[index])
                toggleCollapse(index)
            }}
              className={getClassNameForTableBase(item?.status === 'active', index === 0, index === recruiterDeals.length - 1)}>
              <td className="table-cell d-flex align-items-center">
                <div className={item?.status === 'active' ? "" : "deal-name-text"}>
                  {item?.dealName}
                </div>
              </td>
              <td className="table-cell d-flex align-items-center">
                Created on: {formatDateUSWithMoment(item?.createdAt!)}
              </td>
              <td className="table-cell d-flex align-items-center">
                Last update: {formatDateUSWithMoment(item?.updatedAt!)}
              </td>
              <td className="table-cell d-flex align-items-center">
                {item?.status === 'active' &&
                  <Badge color='success' className="text-capitalize">
                    {item.status}
                  </Badge>}
              </td>
              <td className="table-cell">
                <div className='d-flex align-items-center'>
                  <span className='ml-1'>
                    <Dropdown
                      isOpen={menu[index]}
                      toggle={() => toggleMenu(index)}  >
                      <DropdownToggle className="menuButton dark-btn">
                        <img className="m-0" src={item?.status === 'active' ? VERTICAL_DOTS_FILLED : VERTICAL_DOTS} />
                      </DropdownToggle>
                      <DropdownMenu
                        modifiers={{
                          setMaxHeight: {
                            enabled: true,
                            fn: (data) => {
                              return {
                                ...data,
                                styles: {
                                  ...data.styles,
                                  overflow: "auto",
                                  maxHeight: "100vh",
                                },
                              };
                            },
                          },
                        }}
                        container={document.body}
                        isOpen={menu[index]}
                        toggle={() => toggleMenu(index)}
                      >
                        <DropdownItem
                          className="tableMenuItem" onClick={() => viewDetail(item)}>
                          {VIEW}
                        </DropdownItem>
                        <DropdownItem
                          className="tableMenuItem" onClick={() => editDetail(item)}>
                          {EDIT}
                        </DropdownItem>
                        <DropdownItem
                          className="tableMenuItem d-flex" onClick={() => {
                            setDealId(item?.id)
                            setDealName(dealName)
                            setActivateDealPopUp(true)
                          }}>
                          {SET_ACTIVE}
                          {loading && <Spinner size="sm" />}
                        </DropdownItem>

                        <DropdownItem
                          disabled={fetchingHistory}
                          className="tableMenuItem d-flex align-items-center" onClick={() => {
                            getDealHistory({ variables: { dealId: item?.id } })
                            toggleCollapse(index)
                          }}>
                          {VIEW_HISTORY}
                          {fetchingHistory && <Spinner size="sm" />}
                        </DropdownItem>
                        <DropdownItem className="d-flex" style={{ color: "#CC0000", paddingBottom: 8, fontSize: 12 }}
                          onClick={() => {
                            setDealId(item?.id)
                            setDeletePopUp(true)
                          }}>
                          {DELETE}
                          {deleting && <Spinner size="sm" />}
                        </DropdownItem>

                      </DropdownMenu>
                    </Dropdown>

                  </span>
                </div >
              </td>
            </tr>
            <Collapse isOpen={collapse[index]} style={{ maxHeight: "45vh", border: 'none', overflow: 'auto' }}>
              <div className="m-3">

                {dealHistory?.length ?
                  dealHistory.map((history) => <DealsHistoryStepper dealHistory={history} />)
                  : <NoDataFound text="No History" />}

              </div>
            </Collapse>
          </>
        ))}
      </tbody >
    </table >
    <ConfirmPopUp
      isOpen={deletePopUp}
      toggle={() => setDeletePopUp(!deletePopUp)}
      confirmAction={() => deleteDeal({ variables: { dealId } })}
      confirmText="Are you sure you want to delete this deal?"
      modalHeading="Delete Deal"
    />

    <ConfirmPopUp
      isOpen={activateDealPopUp}
      toggle={() => setActivateDealPopUp(!activateDealPopUp)}
      confirmAction={() => activateDeal({ variables: { dealId } })}
      confirmText={`Are you sure you want to mark ${dealName} as active?`}
      modalHeading={`Activate ${dealName}`}
      btnText="Yes, Activate"
    />
  </div >

}
export default Deals
import { useMutation } from "@apollo/client"
import { FC, useContext } from "react"
import { Controller } from "react-hook-form"
import Select from "react-select"
import { toast } from "react-toastify"
import { Col, Input, Label, Row, Spinner } from "reactstrap"
import { AppContext } from "../../../../../context"
import { CommissionTierProps } from "../../../../../interfaces"
import { BILLING_CREDIT_END_RANGE, BILLING_CREDIT_START_RANGE } from "../../../../../utils/constant"
import { customSelectStyleNew } from "../../../../styled/customSelect"
import { REMOVE_RECRUITER_TIER } from "../../gql"

const Form: FC<CommissionTierProps> = ({ tiersType, serial, setValue, id, register, errors,
  remove, commissionPercentage, refetchDeals, endRange, startRange, control }) => {
  const { theme } = useContext(AppContext)
  // mutation
  const [removeTier, { loading }] = useMutation(REMOVE_RECRUITER_TIER, {
    onCompleted: () => {
      toast.success("Tier Removed Successfully")
      remove(serial)
      refetchDeals()
    },
    onError: () => toast.error("Unable to Remove Tier")
  })
  return <Col className="mb-4" sm='12'>
    <Row>
      <Col sm="12" className="d-flex align-items-center justify-content-between my-3"><Label className="text-14 text-blackele p-0 m-0">Tier {serial + 1}</Label>
        <button className="remove-tier-btn" onClick={() => {
          if (id) {
            removeTier({ variables: { id } })
          } else {
            remove(serial)
          }
        }}>- Remove Tier {loading && <Spinner size='sm' className="mx-2" />}</button>
      </Col>
      <Controller
        name={`${tiersType}.${serial}.tierId`}
        defaultValue={id}
        control={control}
        render={(field) => <input hidden />}
      />
      <Col sm='6' className="mb-3 ">
        <Controller
          name={`${tiersType}.${serial}.startRange`}
          defaultValue={startRange && { value: startRange, label: startRange }}
          rules={{ required: { value: true, message: "Billing Credit Starting Range is a required field" } }}
          control={control}
          as={Select}
          options={BILLING_CREDIT_START_RANGE}
          onChange={(value) => setValue(`${tiersType}.${serial}.startRange`, value)}
          innerRef={register}
          styles={{ ...customSelectStyleNew(theme) }}
          placeholder="Billing Credit Starting Range"
        />
        <small className="text-danger pt-2">
          {!!errors?.[`${tiersType}`]?.length && errors?.[`${tiersType}`][`${serial}`]?.startRange?.message}
        </small>
      </Col>

      <Col sm='6' className="mb-3">
        <Controller
          name={`${tiersType}.${serial}.endRange`}
          defaultValue={endRange && { value: endRange, label: endRange }}
          rules={{ required: { value: true, message: "Billing Credit End Range is a required field" } }}
          control={control}
          as={Select}
          options={BILLING_CREDIT_END_RANGE}
          onChange={(value) => setValue(`${tiersType}.${serial}.endRange`, value)}
          innerRef={register}
          styles={{ ...customSelectStyleNew(theme) }}
          placeholder="Billing Credit End Range"
        />
        <small className="text-danger pt-2">
          {!!errors?.[`${tiersType}`]?.length && errors?.[`${tiersType}`][`${serial}`]?.endRange?.message}
        </small>
      </Col>
    </Row>

    <Col sm='12' className="mb-3 custom-form-input form-input p-0">
      <Controller
        name={`${tiersType}.${serial}.commissionPercentage`}
        control={control}
        rules={{ required: { value: true, message: "Commission Percentage is a required field" } }}
        render={(field) => (
          <Input
            {...field}
            defaultValue={commissionPercentage}
            placeholder="Commission Percentage"
            type="text"
            innerRef={register}
            onChange={({ target: { value } }) => {
              const filteredValue = value.replace(/,/g, '').replace(/\D/g, "")
              setValue(`${tiersType}.${serial}.commissionPercentage`, filteredValue.slice(0, 3))
            }}
            onFocus={(value) => value.target.value && setValue(`${tiersType}.${serial}.commissionPercentage`, value.target.value.replaceAll(" %", ""))}
            onBlur={(value) => value.target.value && setValue(`${tiersType}.${serial}.commissionPercentage`, value.target.value + " %")}
          />
        )}
      />

      <small className="text-danger pt-2">
        {!!errors?.[`${tiersType}`]?.length && errors?.[`${tiersType}`][`${serial}`]?.commissionPercentage?.message}
      </small>
    </Col>

  </Col>
}
export default Form
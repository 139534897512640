import { useLazyQuery } from '@apollo/client';
import classnames from 'classnames';
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { Badge, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import DESIGNATION_TITLE from '../../../assets/images/designation-icon.svg';
import EMAIL_ICON from '../../../assets/images/email-icon.svg';
import PHONE_ICON from '../../../assets/images/phone-icon.svg';
import PREV_IMG from '../../../assets/images/previous.svg';
import { IInfoBarItems, IRecruiterDeal } from "../../../interfaces";
import { FETCH_USER_DETAIL } from '../../../Pages/admin/gql';
import { ACTIONS, BTN_CREATE_DEAL, DEALS, EDIT, EMAIL, FAILED_USER_FETCH, JOB_TITLE, PHONE, RECRUITERS_DEAL_NAV_TABS, TECH_RECRUITER } from '../../../utils/constant';
import { getUserStatusColor } from '../../../utils/helper';
import GoBack from "../../GoBack";
import CustomButton from '../../layout/CustomButton';
import Loader from '../../layout/Loader';
import NoDataFound from '../../layout/NoDataFound';
import { GET_ALL_RECRUITER_DEALS_FOR_RECRUITER } from './gql';
import Header from './header/InfoCard';
import Deals from './list-table';
import RecruiterDealDrawer from './side-drawer';
import ViewDetailRecruitersDeal from './view-detail';

const ViewRecruitersDeal = ({ recruiterId }) => {
  const [infoBarItems, setInfoBarItems] = useState<IInfoBarItems[]>([])
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [viewDetail, setViewDetail] = useState<boolean>(false)
  const [recruiterDeal, setRecruiterDeal] = useState<IRecruiterDeal>()
  const [currentRecruiterDealId, setRecruiterDealId] = useState<string>()
  const [activeTab, setActiveTab] = useState<string>('1')

  // query and mutations
  const [getAllDeals, { data: deals, loading: dealsLoading, refetch }] = useLazyQuery(GET_ALL_RECRUITER_DEALS_FOR_RECRUITER, {
    onCompleted: (val) => {
      if (currentRecruiterDealId) {
        setRecruiterDeal(val?.getRecruiterDealsForRecruiter?.recruiterDeals?.find(({ id }) => id === currentRecruiterDealId))
      }
    }
  })
  const [getRecruiterDetail, { data, loading }] = useLazyQuery(FETCH_USER_DETAIL, {
    onCompleted: (value) => {
      //initializing the content for the info bar
      setInfoBarItems([
        { label: TECH_RECRUITER, src: DESIGNATION_TITLE, tooltip: JOB_TITLE },
        { label: value?.findUserById?.phone, src: PHONE_ICON, tooltip: PHONE },
        { label: value?.findUserById?.email, src: EMAIL_ICON, tooltip: EMAIL }
      ])
    },
    onError: () => toast.error(FAILED_USER_FETCH)
  });

  // fetch recruiter's information
  useEffect(() => {
    getRecruiterDetail(({ variables: { userId: recruiterId } }))
    getAllDeals(({ variables: { recruiterId } }))
  }, [recruiterId])

  // if edit open the side drawer
  useEffect(() => {
    if (isEdit) {
      setDrawerOpen(true)
    }
  }, [isEdit])

  //utility functions
  const toggleTab = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      // history.push(`${location?.pathname}?activeTab=${tab}`);
    }
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen)
    setIsEdit(false)
  }

  if (loading || dealsLoading) {
    return <Loader />
  }

  return (
    <div className="admin-tabs team-tabs">
      {viewDetail ?
        <div className="go-back-div" onClick={() => setViewDetail(!viewDetail)}>
          <img src={PREV_IMG} alt="back" />
          <span style={{ color: "#eaa827" }}>Back</span>
        </div>
        : <GoBack url='/admin/users' />
      }

      <div className="mobileResponsiveFlexAlign justify-content-between primaryHeaderSpacing">
        <div className="d-flex align-items-center">
          <h5 className="m-0 mr-2">{data?.findUserById?.fullName || '--'}</h5>
          <Badge color={getUserStatusColor(data?.findUserById?.status)}
            className="text-capitalize"> {data?.findUserById?.status?.toLowerCase()}</Badge>
        </div>

        <Dropdown
          isOpen={dropdownOpen}
          toggle={() => setDropdownOpen(!dropdownOpen)}
          className="actionsDropdown actionsDropdownSpacing"
        >
          <DropdownToggle caret>{ACTIONS}</DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              disabled={false}
              tag={Link}
              to={`/admin/edit-user/${recruiterId}`}
              className="cursor-pointer m-0"
            >
              {EDIT}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>

      <Header infoBarItems={infoBarItems} />


      <div className="CandidateTabsWrapper">
        <Nav tabs className="navTabsOverflow">
          {RECRUITERS_DEAL_NAV_TABS?.map((item, id) => {
            return <NavItem>
              <NavLink
                className={classnames({ active: activeTab === `${id + 1}` })}
                onClick={() => toggleTab(`${id + 1}`)} >
                {item} </NavLink>
            </NavItem>
          })}
        </Nav>

        <TabContent activeTab={activeTab} className="tab-pane-padding pt-3">
          <TabPane tabId="1">

            {viewDetail ? <ViewDetailRecruitersDeal refetch={refetch} setEditDrawerOpen={setIsEdit}
              recruiterDeal={recruiterDeal!} /> :
              <>
                <div className="d-flex justify-content-between align-items-center px-3">
                  <h4 style={{ fontSize: '1.25rem' }} className="m-0 mr-2">{!!deals?.getRecruiterDealsForRecruiter?.recruiterDeals.length && DEALS}</h4>
                  <CustomButton buttonText={BTN_CREATE_DEAL} buttonClick={() => {
                    setRecruiterDeal(undefined)
                    setRecruiterDealId(undefined)
                    toggleDrawer()
                  }} />
                </div>
                {deals?.getRecruiterDealsForRecruiter?.recruiterDeals.length ?
                  <Deals setEditDrawerOpen={setIsEdit} refetch={refetch} setRecruiterDealId={setRecruiterDealId}
                    setRecruiterDeal={setRecruiterDeal} recruiterDeals={deals?.getRecruiterDealsForRecruiter?.recruiterDeals}
                    toggleViewDetail={() => setViewDetail(!viewDetail)} />
                  : <NoDataFound />
                }
              </>}
          </TabPane>

          <TabPane tabId="2">
            <NoDataFound text='No History Found' />
          </TabPane>

        </TabContent>

      </div>

      <RecruiterDealDrawer isEdit={isEdit} recruiterDeal={recruiterDeal} refetch={refetch}
        isOpen={drawerOpen} recruiterId={recruiterId} toggle={toggleDrawer} />
    </div>
  )
}
export default ViewRecruitersDeal
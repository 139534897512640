import moment from "moment"
import { FC } from "react"
import { TierType } from "../../../../constants"
import { ViewDetailRecruitersDealProps } from "../../../../interfaces"
import CustomButton from "../../../layout/CustomButton"
import CommissionOverwrite from "./commission-overwrite"
import CommissionTiers from "./CommissionTiers"
import DealHistory from "./DealHistory"
import DetailStats from "./DetailStats"

const ViewDetailRecruitersDeal: FC<ViewDetailRecruitersDealProps> = ({ recruiterDeal: { id, recruiterInTraining, createdAt,
  dealEndDate, mentorCommission, assignedManager, dealStartDate, nonRecoverableDraw, drawPercentage, dealName, recruiterDealTiers },
  refetch, setEditDrawerOpen }) => {
  return <div className="px-3">
    <div className="d-flex justify-content-between align-items-center">
      <div className="fw-700 fs-14 m-0 mr-2">{dealName}</div>
      <CustomButton buttonText='Edit Deal' buttonClick={() => setEditDrawerOpen(true)} />
    </div>

    <DetailStats detailStats={[
      recruiterInTraining ? 'Yes' : 'No',
      dealStartDate ? moment(dealStartDate)?.format("MM/DD/YYYY") : '--',
      dealEndDate ? moment(dealEndDate)?.format("MM/DD/YYYY") : '--',
      mentorCommission ? `${mentorCommission} (${assignedManager?.fullName})` : '',
      nonRecoverableDraw ? 'Yes' : 'No',
      drawPercentage || '--']} />

    <CommissionTiers tierType={TierType?.DIRECT_HIRE} refetch={refetch} mappingData={recruiterDealTiers?.filter(({ tierType }) => tierType === TierType.DIRECT_HIRE)
      ?.sort((a, b) => (a?.status === 'active' ? -1 : b?.status === 'active' ? 1 : 0)) || []} />
    <CommissionTiers tierType={TierType?.TEMP} refetch={refetch} mappingData={recruiterDealTiers?.filter(({ tierType }) => tierType === TierType.TEMP)
      ?.sort((a, b) => (a.status === 'active' ? -1 : b.status === 'active' ? 1 : 0))} />

    <CommissionOverwrite recruiterDealId={id} />

    <DealHistory dealId={id} />

  </div >
}

export default ViewDetailRecruitersDeal
import { useEffect } from "react"
import { useFieldArray } from "react-hook-form"
import { Col, Row } from "reactstrap"
import CustomButton from "../../../../layout/CustomButton"
import Form from "./FormFields"

const CommissionTiers = ({ tierType, register, control, tiers, errors, setValue, refetchDeals }) => {
  const { fields, append, remove } = useFieldArray({
    name: tierType,
    control
  })

  useEffect(() => {
    tiers?.forEach(({ id, ...rest }) => append({
      id, ...rest
    }))
  }, [])

  useEffect(() => console.log("tiers :: ", fields), [fields])
  return <Row>
    <Col sm='12' className="mb-4 mt-4 custom-form-input form-input">
      <div className="font-weight-bold">{tierType === 'tempTiers' ? 'Temp' : 'Direct Hire'} Commission Tier</div>
    </Col>
    {fields?.map(({ id, commissionPercentage, ...rest }, index) => (
      <Form
        startRange={rest?.startRange?.value || rest?.startRange} commissionPercentage={commissionPercentage}
        endRange={rest?.endRange?.value || rest?.endRange} id={tiers[index]?.id} refetchDeals={refetchDeals}
        setValue={setValue} register={register} serial={index} errors={errors} tiersType={tierType}
        key={id} remove={remove} control={control} />))}

    <Col sm='12' className="mb-3 custom-form-input form-input">
      <CustomButton
        buttonText="+ Add Tier"
        className="btn-primary w-100"
        buttonClick={() => {
          append({})
        }}
      />
    </Col>
  </Row>
}
export default CommissionTiers
import { useQuery } from "@apollo/client"
import { FC, useContext, useState } from "react"
import { Controller } from "react-hook-form"
import Select from "react-select"
import { Col, FormGroup, Input, Label, Row } from "reactstrap"
import { AppContext } from "../../../../context"
import { BasicInformationProps } from "../../../../interfaces"
import { GET_RECRUITER_LIST } from "../../../../Pages/job-order/gql"
import { priceHandler } from "../../../../utils/helper"
import Loader from "../../../layout/Loader"
import { customSelectStyleNew } from "../../../styled/customSelect"
import moment from "moment"

const BasicInformation: FC<BasicInformationProps> = ({ recruiterDeal, getValues, control, setNonRecoverable, setUnderTraining,
  nonRecoverable, underTraining, errors, register, setValue }) => {
  const [deal90PlusDays, setDeal90PlusDays] = useState<boolean>(recruiterDeal?.dealEndDate ? false : true);
  const [mentorsList, setMentorsList] = useState<{ label: string, value: string }[]>([]);
  const [trainingCompletionList, setTrainingCompletionList] = useState<{ label: string, value: string }[]>(
    recruiterDeal?.dealEndDate ?
      [{ label: `${moment(recruiterDeal?.dealEndDate).add(60, 'days').format("DD MMMM, YYYY")} (60 Days)`, value: `${moment(recruiterDeal?.dealEndDate).add(60, 'days').format("DD MMMM, YYYY")} (60 Days)` },
      { label: `${moment(recruiterDeal?.dealEndDate).add(90, 'days').format("DD MMMM, YYYY")} (90 Days)`, value: `${moment(recruiterDeal?.dealEndDate).add(60, 'days').format("DD MMMM, YYYY")} (90 Days)` }
      ] : []);
  const { theme } = useContext(AppContext)

  // query and mutations
  const { loading } = useQuery(GET_RECRUITER_LIST, {
    onCompleted: (data) => setMentorsList(data?.getRecruiterList.map(({ id, fullName }) => { return { label: fullName, value: id } }))
  })

  if (loading) {
    return <Loader />
  }

  return <Row >

    <Col sm='12' className="mb-3 custom-form-input form-input">
      <Label className="text-14 text-blackele pb-2">Deal Name <span className="text-danger">*</span></Label>
      <Controller
        name="dealName"
        rules={{ required: { value: true, message: "Deal Name is a required field" } }}
        control={control}
        render={(field) => (
          <Input
            {...field}
            defaultValue={recruiterDeal?.dealName}
            innerRef={register}
            placeholder="Enter deal name"
            type="text"
            onChange={(item) => field.onChange(item.target.value)}
          />
        )}
      />
      <small className="text-danger pt-2">
        {errors?.dealName?.message}
      </small>
    </Col>

    <Col xs='6' className="mb-4 custom-form-input form-input">
      <Label className="text-14 text-blackele pb-2">Deal Start Date <span className="text-danger">*</span> </Label>
      <Controller
        rules={{ required: { value: true, message: "Deal Start Date is a required field" } }}
        name="dealStartDate"
        control={control}
        render={(field) => (
          <Input
            {...field}
            defaultValue={recruiterDeal?.dealStartDate}
            innerRef={register}
            placeholder="Select Date"
            type="date"
          />
        )}
      />
      <small className="text-danger pt-2">
        {errors?.dealStartDate?.message}
      </small>
    </Col>
    <Col xs='6' className="mb-4 custom-form-input form-input">
      <Label className="text-14 text-blackele pb-2">Deal End Date </Label>
      <Controller
        name="dealEndDate"
        control={control}
        render={(field) => (
          <Input
            {...field}
            defaultValue={recruiterDeal?.dealEndDate}
            innerRef={register}
            placeholder="Select Date"
            type="date"
            onChange={(val) => {
              setDeal90PlusDays(val?.target?.value ? false : true)
              setTrainingCompletionList([
                { label: `${moment(val?.target?.value).add(60, 'days').format("DD MMMM, YYYY")} (60 Days)`, value: `${moment(val?.target?.value).add(60, 'days').format("DD MMMM, YYYY")} (60 Days)` },
                { label: `${moment(val?.target?.value).add(90, 'days').format("DD MMMM, YYYY")} (90 Days)`, value: `${moment(val?.target?.value).add(60, 'days').format("DD MMMM, YYYY")} (90 Days)` }
              ])
              if (getValues()?.trainingCompletionDate?.value?.includes("60 Days")) {
                setValue("trainingCompletionDate", { label: `${moment(val?.target?.value).add(60, 'days').format("DD MMMM, YYYY")} (60 Days)`, value: `${moment(val?.target?.value).add(60, 'days').format("DD MMMM, YYYY")} (60 Days)` })
              }
              else if (getValues()?.trainingCompletionDate?.value?.includes("90 Days")) {
                setValue("trainingCompletionDate", { label: `${moment(val?.target?.value).add(90, 'days').format("DD MMMM, YYYY")} (90 Days)`, value: `${moment(val?.target?.value).add(60, 'days').format("DD MMMM, YYYY")} (90 Days)` })
              }
            }}
          />
        )}
      />
      <small className="text-danger pt-2">
        {errors?.dealEndDate?.message}
      </small>
    </Col>

    <Col xs='12' className="mb-3 custom-form-input form-input">
      <FormGroup className=" d-flex align-items-center ml-3 my-1 pl-1">
        <Input
          defaultChecked={Boolean(recruiterDeal?.recruiterInTraining)}
          onChange={(value) => setUnderTraining(value.target.checked)}
          placeholder="Select Date" type="checkbox" />
        <span className="checkboxTextStyle">
          Recruiter in training?
          <span className="color-gray"> (If yes then please assign from the list)</span>
        </span>
      </FormGroup>
    </Col>

    {underTraining &&
      <>
        <Col sm='6' className="mb-3">
          <Label className="text-14 text-blackele pb-2">Assign Mentor <span className="text-danger">*</span></Label>
          <Controller
            name="assignedManagerId"
            defaultValue={recruiterDeal?.assignedManagerId && { value: recruiterDeal?.assignedManagerId, label: recruiterDeal?.assignedManager?.fullName }}
            rules={{ required: { value: underTraining, message: "Assign Mentor is a required field" } }}
            control={control}
            as={Select}
            options={mentorsList}
            onChange={(value) => setValue('assignedManagerId', value)}
            innerRef={register}
            styles={{ ...customSelectStyleNew(theme) }}
            placeholder="Select Mentor"
          />
          <small className="text-danger pt-2">
            {errors?.assignedManagerId?.message}
          </small>
        </Col>

        <Col sm='6' className="mb-3 custom-form-input form-input">
          <Label className="text-14 text-blackele pb-2">Mentor Commission <span className="text-danger">*</span></Label>
          <Controller
            name="mentorCommission"
            defaultValue={recruiterDeal?.mentorCommission}
            rules={{ required: { value: underTraining, message: "Mentor Commission is a required field" } }}
            control={control}
            render={(field) => (
              <Input
                {...field}
                placeholder="Mentor Percentage"
                type="text"
                onChange={({ target: { value } }) => {
                  const filteredValue = value.replace(/,/g, '').replace(/\D/g, "")
                  setValue("mentorCommission", filteredValue.slice(0, 3))
                }}
                onFocus={(value) => value.target.value && setValue("mentorCommission", value.target.value.replaceAll(" %", ""))}
                onBlur={(value) => value.target.value && setValue("mentorCommission", value.target.value + " %")}
              />
            )}
          />
          <small className="text-danger pt-2">
            {errors?.mentorCommission?.message}
          </small>
        </Col>

        {!deal90PlusDays &&
          <Col sm='6' className="mb-3">
            <Label className="text-14 text-blackele pb-2">Training Completion <span className="text-danger">*</span></Label>
            <Controller
              name="trainingCompletionDate"
              defaultValue={recruiterDeal?.trainingCompletionDate && { value: recruiterDeal?.trainingCompletionDate, label: recruiterDeal?.trainingCompletionDate }}
              rules={{ required: { value: underTraining && !deal90PlusDays, message: "Training Completion is a required field" } }}
              control={control}
              as={Select}
              options={trainingCompletionList}
              onChange={(value) => setValue('assignedManagerId', value)}
              innerRef={register}
              styles={{ ...customSelectStyleNew(theme) }}
              placeholder="Select Completion Date"
            />
            <small className="text-danger pt-2">
              {errors?.trainingCompletionDate?.message}
            </small>
          </Col>}
      </>
    }

    <Col xs='12' className="mb-4 custom-form-input form-input">
      <FormGroup className=" d-flex align-items-center ml-3 my-1 pl-1">
        <Input
          defaultChecked={Boolean(recruiterDeal?.nonRecoverableDraw)}
          onChange={(value) => setNonRecoverable(value.target.checked)}
          placeholder="Select Date" type="checkbox" />
        <span className="checkboxTextStyle">Non Recoverable Draw</span>
      </FormGroup>
    </Col>

    <Col sm='6' className="mb-3 custom-form-input form-input">
      <Label className="text-14 text-blackele pb-2">Recruiter Draw Percentage <span className="text-danger">*</span></Label>
      <Controller
        name="drawPercentage"
        rules={{ required: { value: true, message: "Recruiter Draw Percentage is a required field" } }}
        control={control}
        render={(field) => (
          <Input
            {...field}
            defaultValue={recruiterDeal?.drawPercentage}
            innerRef={register}
            placeholder="Percentage"
            type="text"
            onChange={({ target: { value } }) => {
              const filteredValue = value.replace(/,/g, '').replace(/\D/g, "")
              setValue("drawPercentage", filteredValue.slice(0, 3))
            }}
            onFocus={(value) => value.target.value && setValue("drawPercentage", value.target.value.replaceAll(" %", ""))}
            onBlur={(value) => value.target.value && setValue("drawPercentage", value.target.value + " %")}
          />
        )}
      />
      <small className="text-danger pt-2">
        {errors?.drawPercentage?.message}
      </small>
    </Col>

    {deal90PlusDays && <Col sm='6' className="mb-3 custom-form-input form-input">
      <Label className="text-14 text-blackele pb-2">Contracting Commission <span className="text-danger">*</span></Label>
      <Controller
        name="contractingCommission"
        rules={{ required: { value: true, message: "Contracting Commission is a required field" } }}
        control={control}
        render={(field) => (
          <Input
            {...field}
            defaultValue={recruiterDeal?.contractingCommission}
            innerRef={register}
            placeholder="25 %"
            type="text"
            onChange={({ target: { value } }) => {
              const filteredValue = value.replace(/,/g, '').replace(/\D/g, "")
              setValue("contractingCommission", filteredValue.slice(0, 3))
            }}
            onFocus={(value) => value.target.value && setValue("contractingCommission", value.target.value.replaceAll(" %", ""))}
            onBlur={(value) => value.target.value && setValue("contractingCommission", value.target.value + " %")}
          />
        )}
      />
      <small className="text-danger pt-2">
        {errors?.contractingCommission?.message}
      </small>
    </Col>}

    {!deal90PlusDays &&
      <>
        <Col sm='6' className="mb-3 custom-form-input form-input">
          <Label className="text-14 text-blackele pb-2">Bi-Weekly Draw <span className="text-danger">*</span></Label>
          <Controller
            name="biWeeklySalary"
            control={control}
            rules={{ required: { value: !deal90PlusDays, message: "Bi-Weekly Draw is a required field" } }}
            render={(field) => (
              <Input
                {...field}
                defaultValue={recruiterDeal?.biWeeklySalary}
                placeholder="Amount"
                type="text"
                innerRef={register}
                onChange={({ target: { value } }) => {
                  const filteredValue = value.replace(/,/g, '').replace(/\D/g, "")
                  setValue("biWeeklySalary", priceHandler(filteredValue))
                }}
                onFocus={(value) => value.target.value && setValue("biWeeklySalary", value.target.value.replaceAll("$ ", ""))}
                onBlur={(value) => value.target.value && setValue("biWeeklySalary", "$ " + value.target.value)}
              />
            )}
          />
          <small className="text-danger pt-2">
            {errors?.biWeeklySalary?.message}
          </small>
        </Col>


        <Col sm='6' className="mb-3 custom-form-input form-input">
          <Label className="text-14 text-blackele pb-2">Contracting Base Rate <span className="text-danger">*</span></Label>
          <Controller
            name="contractingBaseRate"
            rules={{ required: { value: !deal90PlusDays, message: "Contracting Base Rate is a required field" } }}
            control={control}
            render={(field) => (
              <Input
                {...field}
                defaultValue={recruiterDeal?.contractingBaseRate}
                placeholder="25 %"
                type="text"
                innerRef={register}
                onChange={({ target: { value } }) => {
                  const filteredValue = value.replace(/,/g, '').replace(/\D/g, "")
                  setValue("contractingBaseRate", filteredValue.slice(0, 3))
                }}
                onFocus={(value) => value.target.value && setValue("contractingBaseRate", value.target.value.replaceAll(" %", ""))}
                onBlur={(value) => value.target.value && setValue("contractingBaseRate", value.target.value + " %")}
              />
            )}
          />
          <small className="text-danger pt-2">
            {errors?.contractingBaseRate?.message}
          </small>
        </Col>

        <Col sm='6' className="mb-3 custom-form-input form-input">
          <Label className="text-14 text-blackele pb-2">Direct Hire Base Rate <span className="text-danger">*</span></Label>
          <Controller
            name="dhBaseRate"
            rules={{ required: { value: !deal90PlusDays, message: "Direct Hire Base Rate is a required field" } }}
            control={control}
            render={(field) => (
              <Input
                {...field}
                defaultValue={recruiterDeal?.dhBaseRate}
                placeholder="25 % "
                type="text"
                innerRef={register}
                onChange={({ target: { value } }) => {
                  const filteredValue = value.replace(/,/g, '').replace(/\D/g, "")
                  setValue("dhBaseRate", filteredValue.slice(0, 3))
                }}
                onFocus={(value) => value.target.value && setValue("dhBaseRate", value.target.value.replaceAll(" %", ""))}
                onBlur={(value) => value.target.value && setValue("dhBaseRate", value.target.value + " %")}
              />
            )}
          />
          <small className="text-danger pt-2">
            {errors?.dhBaseRate?.message}
          </small>
        </Col>
      </>}
  </Row >
}
export default BasicInformation